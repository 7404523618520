import { useState, useEffect } from "react"
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap"
import { GetAllAccounts } from "../../../utils/proxy/AccountsProxy";
import { GetAllCompanyContactsByAccountId } from "../../../utils/proxy/CompanyContactProxy";
import Select from "react-select";
import { GetAllProducts } from "../../../utils/proxy/ProductsProxy";
import { IoMdClose } from "react-icons/io";
import AccountModal from "../../Products/AccountModal";
import { useLocation, useNavigate } from "react-router-dom";
import { createQuote } from "../../../utils/proxy/QuoteProxy";
import Swal from "sweetalert2";
import { FaPlus } from "react-icons/fa";
import { GetTaxRulesByState, getAlltcsTaxes, getAlltdsTaxes } from "../../../utils/proxy/TaxProxy";
import { IoHomeOutline } from "react-icons/io5";

export const QuoteModal = () => {

    const { state } = useLocation();
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(state && state.accountDataProp ? { ...state.accountDataProp, value: state.accountDataProp?.id, label: state.accountDataProp?.accountname } : null);
    const [selectedAccountId, setSelectedAccountId] = useState(state && state.accountDataProp ? state.accountDataProp?.id : null);

    const [companyContacts, setCompanyContacts] = useState([]);
    const [selectedContacts, setSelectedcontacts] = useState(null);
    const [selectedContactId, setSelectedcontactId] = useState();

    const [products, setProducts] = useState([]);
    const [grandTotal, setGrandTotal] = useState(0);
    const [data, setData] = useState([{
        "productid": null,
        "quantity": 0,
        "price": 0,
        "discount": 0,
        "total": 0,
        "taxed": false,
        "taxprice": 0,
        "description": null
    }])
    const [form, setForm] = useState(false);
    const [reload, setReload] = useState(false);
    const [productPricing, setProductPricing] = useState({});
    const [note, setNote] = useState(null);
    const [leadData, setLeadData] = useState(state && state.accountDataProp ? { ...state.leadDataProp,
         company: state.accountDataProp.accountname,
        firstname:null,
        lastname: null,
        website: state.accountDataProp.website,
        phone: state.accountDataProp.phone,
        email: state.accountDataProp.email,
        street: state.accountDataProp.billing_address_street,
        cityid: state.accountDataProp.billing_address_cityid,
        stateid: state.accountDataProp.billing_address_stateid,
        countryid: state.accountDataProp.billing_address_countryid,
        totalemployees: state.accountDataProp.employees,
        quoteownerid: localStorage.getItem('uuid'),
        industryid: state.accountDataProp.industryid } : {
            quoteownerid: localStorage.getItem('uuid'),
        });
    const [sumbitting, setSubmitting] = useState(false);
    const [dueDate, setDueDate] = useState('');

    const [taxRules, setTaxRules] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [rate, setRate] = useState(0);
    const [finalTotal,setFinalTotal] = useState();
    const [tcsid, setTcsId] = useState();
    const [tcsrate, setTcsRate] = useState();
    const [tdsid, setTdsId] = useState();
    const [tdsrate, setTdsRate] = useState();
    const [radioValue, setRadioValue] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        products.forEach(product => {
            productPricing[product.id] = product.price;
        });
    }, [products]);

    useEffect(() => {
        const getallaccount = async () => {
            let d = await GetAllAccounts();
            if (d) {
                setAccounts(d);
            } else {
                alert('Error', 'Issues while fetching data')
            }
        }

        const getallproducts = async () => {
            let d = await GetAllProducts();
            if (d) {
                setProducts(d);
                const pricing = {};
                d.forEach(product => {
                    pricing[product.id] = product.price;
                });
                setProductPricing(pricing);
            } else {
                alert('Error', 'Issues while fetching data')
            }
        }

        getallaccount()
        getallproducts()
    }, [reload])

    useEffect(() => {
        const getallCompanyContacts = async () => {
            let d = await GetAllCompanyContactsByAccountId(selectedAccountId);
            if (d) {
                setCompanyContacts(d);
            } else {
                alert('Error', 'Issues while fetching data')
            }
        }

        selectedAccountId && getallCompanyContacts()
    }, [selectedAccountId])


    const accountsOption = [];
    const contactsOption = [];

    accounts.map((project) => {
        accountsOption.push({
            ...project,
            value: project.id,
            label: project.accountname
        })
    })

    companyContacts.map((project) => {
        contactsOption.push({
            ...project,
            value: project.id,
            label: project.firstname + " " + project.lastname
        })
    })

    useEffect(() => {
        if (state && state.selectedproductsProp.length > 0) {
            const updatedData = state.selectedproductsProp.map(d => ({
                productid: d.value,
                quantity: 1,
                price: productPricing[d.value] || 0,  // Use a default value if productPricing is not populated yet
                discount: 0,
                total: productPricing[d.value] || 0,
                taxed: false,
                taxprice: 0,
                description: null
            }));
            setData(updatedData);
        }

    }, [state, productPricing]);

    useEffect(() => {
        if (selectedAccount) {
            getTaxRules(selectedAccount?.billing_address_stateid);
        }
    }, [selectedAccount])

    const handleAccountchange = (selected) => {
        if (selected === null) {
            setSelectedAccount();
            setSelectedAccountId();
            setSelectedcontacts(null);
            setSelectedcontactId();
            setTaxRules([]);
        } else {
            setSelectedAccount(selected);
            setSelectedAccountId(selected.value);
            setSelectedcontacts(null);
            setSelectedcontactId();
            setLeadData(lead => ({ ...lead, company: selected?.accountname, website: selected?.website, phone: selected?.phone, email: selected?.email, street: selected?.billing_address_street, cityid: selected?.billing_address_cityid, stateid: selected?.billing_address_stateid, countryid: selected?.billing_address_countryid, totalemployees: selected?.employees, industryid: selected?.industryid }))
        }

    };

    const handleContactchange = (selected) => {
        if (selected === null) {
            setSelectedcontacts();
            setSelectedcontactId();
        } else {
            console.log(selected)
            setSelectedcontacts(selected);
            setSelectedcontactId(selected.value);
            setLeadData({...leadData, firstname: selected.firstname,lastname: selected.lastname,contactid: selected.id,salutationid: selected.salutationid,title:selected.title,})
        }

    };

    function calculateDiscount(originalPrice, discountPercentage, operation) {

        const discountAmount = (originalPrice * discountPercentage) / 100;
        let finalPrice = 0;

        if (operation == 'add') {
            finalPrice = originalPrice + discountAmount;
        } else {
            finalPrice = originalPrice - discountAmount;
        }
        return finalPrice;
    }

    const handleRemove = (index) => {
        if (data.length > 1) {
            const updatedData = data.filter((_, i) => i !== index);
            setData(updatedData);
        }
    };

    const getTaxRules = async (state) => {
        let d = await GetTaxRulesByState(state);
        if (d) {
            setTaxRules(d);
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Failed To Fetch Tax',
                icon: 'error'
            })
        }
    }

    const handleRadio = (e) => {
        setForm(true);
    }

    const handleRadioChange = async (value) => {
        setRadioValue(value);
        setTaxes([]);
        setRate(0);
        let d;
        if (value === 'tds') {
            d = await getAlltdsTaxes();
            setTaxes(d);
        } else {
            d = await getAlltcsTaxes();
            setTaxes(d)
        }
    }

    const handleTdsTcsChange = (obj) =>{
        setTdsId();
        setTdsRate();
        setTcsId();
        setTcsRate();
        const {id, rate} = JSON.parse(obj);
        if (radioValue === 'tds') {
            setTdsId(id);
            setTdsRate(rate);
        }else{
            setTcsId(id);
            setTcsRate(rate);
        }
        setRate(rate)
        let k = calculateDiscount(finalTotal,rate,'minus');
        setFinalTotal(k);
    }

    useEffect(() => {

        const productTotal = data.reduce((sum, item)=>{
            return sum + parseFloat(item.total || 0);
        },0)

        setGrandTotal(productTotal);

        const total = data.reduce((sum, item) => {
            if (item.taxed) {
                return sum + parseFloat(item.taxprice || 0);
            }

            return sum + parseFloat(item.total || 0);
        }, 0);

        let temp = calculateDiscount(total,rate,'minus');
        // setGrandTotal(total);
        setFinalTotal(temp);
    }, [data,rate])

    const handleChange = (index, key, value) => {
        const updatedData = data.map((item, i) => {
            if (key === 'productid' && i === index) {
                if (item.taxed) {
                    let taxrate = taxRules.reduce((s, r) => s + r.taxrate, 0);
                    item = { ...item, 'quantity': 1, 'price': productPricing[value], 'total': calculateDiscount((productPricing[value] * 1), item.discount, 'minus'),'taxprice': calculateDiscount(calculateDiscount((productPricing[value] * 1), item.discount, 'minus'), taxrate, 'add') }
                }else{
                    item = { ...item, 'quantity': 1, 'price': productPricing[value], 'total': calculateDiscount((productPricing[value] * 1), item.discount, 'minus') }
                }
            }
            if (key === 'price' && i === index) {
                if (item.taxed) {
                    let taxrate = taxRules.reduce((s, r) => s + r.taxrate, 0);
                    item = { ...item, 'price': value, 'discount': 0, 'total': value * item.quantity, 'taxprice': calculateDiscount(value * item.quantity, taxrate, 'add') }
                } else {
                    item = { ...item, 'price': value, 'discount': 0, 'total': value * item.quantity }
                }
            }
            if (key === 'quantity' && i === index) {
                if (item.taxed) {
                    let taxrate = taxRules.reduce((s, r) => s + r.taxrate, 0);
                    let k = calculateDiscount(item.price * value, item.discount, 'minus')
                    item = { ...item, 'total': k, 'taxprice': calculateDiscount(k, taxrate, 'add') }
                } else {
                    item = { ...item, 'total': calculateDiscount(item.price * value, item.discount, 'minus') }
                }
            }
            if (key === 'discount' && i === index && value >= 0 && value <= 100) {
                if (item.taxed) {
                    let taxrate = taxRules.reduce((s, r) => s + r.taxrate, 0);
                    item = { ...item, 'total': calculateDiscount((item.price * item.quantity), value, 'minus'), 'taxprice': calculateDiscount(calculateDiscount((item.price * item.quantity), value, 'minus'), taxrate, 'add') }
                } else {
                    item = { ...item, 'total': calculateDiscount((item.price * item.quantity), value, 'minus') }
                }
            }
            if (key === 'taxed' && i === index) {
                if (value) {
                    let taxrate = taxRules.reduce((s, r) => s + r.taxrate, 0);
                    item = { ...item, 'taxed': value, 'taxprice': calculateDiscount(item.total, taxrate, 'add') }
                } else {
                    item = { ...item, 'taxed': value, 'taxprice': 0 }
                }
            }
            if (i === index) {
                return { ...item, [key]: value };
            }
            return item;
        });
        setData(updatedData);
    };

    const handleSubmit = async () => {
        if (selectedAccountId == null || selectedAccountId == '') {
            Swal.fire({
                title: 'Warning',
                text: 'Select Account',
                icon: 'warning'
            })
        } else if (selectedContactId == null || selectedContactId == '') {
            Swal.fire({
                title: 'Warning',
                text: 'Select Contact',
                icon: 'warning'
            })
        } else if (dueDate == null || dueDate == '') {
            Swal.fire({
                title: 'Warning',
                text: 'Select Validity Date',
                icon: 'warning'
            })
        } else if (leadData == null || Object.keys(leadData).length === 0) {
            Swal.fire({
                title: 'Warning',
                text: 'Some Data is Missing',
                icon: 'warning'
            })
        } else {
            setSubmitting(true);
            let newQuoteData = {
                ...leadData,
                products: data,
                note: note,
                duedate: dueDate,
                accountid: selectedAccountId,
                contactid: selectedContactId,
                taxes:taxRules.map(({ id: taxid, taxrate: rate }) => ({ taxid, rate })),
                tdsid,
                tdsrate,
                tcsid,
                tcsrate
            };

            
            let d = await createQuote(newQuoteData);
            if (d && d.status === 'success') {
                Swal.fire({
                    title: 'Success',
                    text: 'Quotation Created Successfully',
                    icon: 'success'
                })
                setSubmitting(false);
                navigate('/quote-master')
            }
            setSubmitting(false);
        }

    }


    return (
        <Container fluid style={{ minHeight: "80vh" }}>

            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}

                        /></a></li>
                        <li><a href="/quotecr"><span class="icon icon-beaker"> </span>Create Quote</a></li>

                    </ul>

                </div>

            </div>

            <div className="glass-body" style={{ margin: 10 }}>
                <div className="mb-3 d-flex gap-4">
                    {/* <Form.Check
                        label="Existing Account"
                        name="group1"
                        type="radio"
                        value='existing'
                        onChange={(e) => handleRadio(e)}
                        id={`inline-radio-1`}
                        checked={form === 'existing'}
                    />
                    <Form.Check
                        label="New Account"
                        name="group1"
                        type="radio"
                        value='new'
                        onChange={(e) => handleRadio(e)}
                        id={`inline-radio-2`}
                        checked={form === 'new'}
                    /> */}
                    <Button onClick={(e) => handleRadio(e)}>Create New Account</Button>
                </div>

                <div className="m-4 d-flex flex-direction-column gap-2">
                    <div style={{ flexGrow: 1 }}>
                        <Form.Label>Account</Form.Label>
                        <Form.Group>
                            <div style={{ border: '1px solid #ced4da', borderRadius: '.375rem' }}>
                                <Select
                                    options={accountsOption}
                                    value={selectedAccount}
                                    onChange={handleAccountchange}
                                    isClearable={true}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        <Form.Label>Contacts</Form.Label>
                        <Form.Group>
                            <div style={{ border: '1px solid #ced4da', borderRadius: '.375rem' }}>
                                <Select
                                    options={contactsOption}
                                    value={selectedContacts}
                                    onChange={handleContactchange}
                                    isClearable={true}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>

                {selectedAccount && <div style={{ background: '#0000000d' }} className="m-4 d-flex flex-direction-column gap-2">
                    <div>
                        <div style={{ margin: 8 }}>
                            <p><b>Billing Address Street  :  </b>{selectedAccount.billing_address_street ? selectedAccount.billing_address_street : 'N/A'}</p>
                            <p><b>Billing Address City  :  </b>{selectedAccount.billing_address_cityname ? selectedAccount.billing_address_cityname : 'N/A'}</p>
                            <p><b>Billing Address State  :  </b>{selectedAccount.billing_address_statename ? selectedAccount.billing_address_statename : 'N/A'}</p>
                            <p><b>Billing Address Country  :  </b>{selectedAccount.billing_address_countryname ? selectedAccount.billing_address_countryname : 'N/A'}</p>
                        </div>
                    </div>
                    <div>
                        <div style={{ margin: 8 }}>
                            <p><b>Shipping Address Street  :  </b>{selectedAccount.shipping_address_street ? selectedAccount.shipping_address_street : 'N/A'}</p>
                            <p><b>Shipping Address City  :  </b>{selectedAccount.shipping_address_cityname ? selectedAccount.shipping_address_cityname : 'N/A'}</p>
                            <p><b>Shipping Address State  :  </b>{selectedAccount.shipping_address_statename ? selectedAccount.shipping_address_statename : 'N/A'}</p>
                            <p><b>Shipping Address Country  :  </b>{selectedAccount.shipping_address_countryname ? selectedAccount.shipping_address_countryname : 'N/A'}</p>
                        </div>
                    </div>
                </div>}

                <div className="m-4">
                    <Row>
                        <Col>
                            <Form.Label>Valid Till</Form.Label>
                            <Form.Control
                                type="date"
                                onChange={(e) => setDueDate(new Date(e.target.value).toISOString())}
                            />
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </div>

                <div className="m-4">
                    <Table striped bordered responsive='md' className="mt-3">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Qty</th>
                                <th>Price</th>
                                <th>Discount (%)</th>
                                <th>Total</th>
                                <th>Taxed</th>
                                <th>Tax Amount</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, index) => (
                                <tr key={index}>
                                    <td width={'22%'}>
                                        <Form.Select value={row.productid}
                                            onChange={(e) => handleChange(index, 'productid', parseInt(e.target.value))}>
                                            <option value={''}>Select Product</option>
                                            {products.map(saluation => (
                                                <option value={saluation.id}>{saluation.name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Product Description"
                                            value={row.description}
                                            onChange={(e) => { handleChange(index, 'description', e.target.value) }}
                                        />
                                    </td>
                                    <td width={'13%'}>
                                        <Form.Control
                                            type="number"
                                            value={row.quantity}
                                            min={0}
                                            onChange={(e) => { handleChange(index, 'quantity', parseInt(e.target.value)) }}
                                        />
                                    </td>
                                    <td width={'17%'}>
                                        <Form.Control
                                            type="number"
                                            value={row.price}
                                            onChange={(e) => { handleChange(index, 'price', parseInt(e.target.value)) }}
                                        />
                                    </td>
                                    <td width={'13%'}>
                                        <Form.Control
                                            type="number"
                                            value={row.discount}
                                            max={100}
                                            min={0}
                                            onChange={(e) => { handleChange(index, 'discount', parseInt(e.target.value)) }}
                                        />
                                    </td>
                                    <td width={'15%'}>
                                        <Form.Control
                                            type="number"
                                            onChange={(e) => { handleChange(index, 'total', parseInt(e.target.value)) }}
                                            value={row.total}
                                            placeholder="total"
                                            disabled />
                                    </td>
                                    <td width={'6%'}>
                                        <input inline
                                            type="checkbox"
                                            aria-label="option 1"
                                            onChange={(e) => { handleChange(index, 'taxed', e.target.checked) }}
                                            checked={row.taxed} />
                                    </td>
                                    <td width={'10%'}>
                                        <Form.Control
                                            type="text"
                                            value={row.taxprice}
                                            placeholder="Tax Amount"
                                            disabled />
                                    </td>
                                    <td width={'6%'}>
                                        <IoMdClose onClick={() => handleRemove(index)} size={20} color="red" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div>
                        <Button variant="primary" onClick={() => {
                            setData(prev => [...prev, {
                                "productid": null,
                                "quantity": 0,
                                "price": 0,
                                "discount": 0,
                                "total": 0,
                                "taxed": false,
                                "taxprice": 0,
                                "description": null
                            }])
                        }}>
                            <FaPlus /> Add
                        </Button>
                    </div>

                    <Row className="mt-3">
                        <Col md={6}>
                            <Form.Group controlId="formNotes">
                                <Form.Label>Notes</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                    placeholder="Notes"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Table bordered>
                                <tbody>
                                    {/* <tr>
                                        <td>Sub Total</td>
                                        <td>{grandTotal}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Total</td>
                                        <td>{grandTotal}</td>
                                    </tr>
                                    <tr>
                                        <td>{taxRules.map(d => (
                                            <div>{d.name + ' - ' + d.taxrate + '%'}</div>
                                        ))}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="align-items-center justify-content-start gap-3">
                                                <div key={`default-radio`} className="mb-3 d-flex m-2">
                                                    <Form.Check // prettier-ignore
                                                        type='radio'
                                                        id={`default-radio-tds`}
                                                        label={`TDS`}
                                                        value={'tds'}
                                                        name="group1"
                                                        onChange={e => handleRadioChange(e.target.value)}
                                                    />

                                                    <Form.Check
                                                        type='radio'
                                                        label={`TCS`}
                                                        value={'tcs'}
                                                        id={`default-radio-tcs`}
                                                        name="group1"
                                                        onChange={e => handleRadioChange(e.target.value)}
                                                    />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Select onChange={(e) => handleTdsTcsChange(e.target.value)} aria-label="Default select example">
                                                                <option value={'{"rate":0}'}>--Select--</option>
                                                                {taxes.map(saluation => (
                                                                    <option key={saluation.id} value={JSON.stringify(saluation)}>{saluation.taxname}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {rate && rate+'%'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Grand Total &#x28; Tax Inclusive &#x29;</td>
                                        <td>{finalTotal && Math.floor(finalTotal)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 10 }}>
                    <Button disabled={sumbitting} onClick={() => handleSubmit()}>{sumbitting ? 'Loading...' : 'Save'}</Button>
                </div>

            </div>
            <AccountModal
                fullscreen={true}
                show={form}
                setShow={setForm}
                setReload={setReload}
                opttype={'create'}
            />
        </Container>
    )
}